import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import Layout from "../layout"
import ImageQuotePanel from "../components/image-quote-panel"

const MainLayout = styled.div`
  margin: 20px auto;
`

const Heading = styled.h1`
  border-top: 1px solid #cdcdcd;
  border-bottom: 1px solid #cdcdcd;
  padding: 12px 0;
  margin: 0 0 25px;
  font-size: 1.4rem;
  text-transform: uppercase;
`

const MainPanel = styled.div`
  @media (min-width: 768px) {
    float: right;
    width: 68%;
  }
`

const BookTemplate = ({ data }) => {
  return (
    <Layout>
      <MainLayout className="container container--padded cf">
        <MainPanel>
          <Heading>{data.markdownRemark.frontmatter.title}</Heading>
          <p>
            <strong>{data.markdownRemark.frontmatter.year}</strong>{" "}
            {data.markdownRemark.frontmatter.publisher}
          </p>
          <div
            className="cms-content"
            dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
          />
        </MainPanel>
        <ImageQuotePanel image={data.markdownRemark.frontmatter.image} />
      </MainLayout>
    </Layout>
  )
}

export default BookTemplate

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 280) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        year
        publisher
      }
    }
  }
`
